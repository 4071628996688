<template>
  <b-row>
    <b-col md="12 d-flex flex-column align-items-center">
      <!-- weekly calendar -->
      <FullCalendar
        ref="tripCalendar"
        :options="calendarOptions"
      />
      <b-card-code
        title=""
        no-body
        class="w-100"
      >
        <b-card-body class="w-100">
          <!-- upload csv button -->
          <div class="d-flex justify-content-end  flex-wrap">
            <b-button
              variant="warning"
              @click="format()"
            >
              Format
            </b-button>
            <div>
              <label
                for="import-csv"
                class="btn btn-success"
              >
                Import CSV
              </label>
              <b-form-file
                id="import-csv"
                v-model="file"
                class="d-none"
                @input="uploadCSV"
              />
            </div>
          </div>
        </b-card-body>

        <!-- Trip Table -->
        <trip-table
          v-if="items.length > 0"
          :items="items"
        />

        <div
          v-if="items.length == 0"
          class="text-center px-5 py-5"
        >
          There is no content!
        </div>

      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BFormFile, BCardBody, BRow, BCol, VBTooltip, BButton,
} from 'bootstrap-vue'

import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'

import * as tripService from '@/services/trip'
import TripTable from './Table.vue'

export default {
  components: {
    BCardCode,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormFile,
    TripTable,
    FullCalendar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      items: [],
      file: null,
      csv: null,
      calendarOptions: {
        plugins: [listPlugin],
        initialView: 'listWeek',
        // calendar next and prev buttons
        customButtons: {
          // this overrides the prev button
          prev: {
            text: 'left-single-arrow',
            click: () => {
              const calendarApi = this.$refs.tripCalendar.getApi()
              calendarApi.prev()
              this.getDayOfWeek(calendarApi.currentData.viewTitle)
            },
          },
          // this overrides the next button
          next: {
            text: 'right-single-arrow',
            click: () => {
              const calendarApi = this.$refs.tripCalendar.getApi()
              calendarApi.next()
              this.getDayOfWeek(calendarApi.currentData.viewTitle)
            },
          },
        },
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next',
        },
      },
      weekStart: '',
      weekEnd: '',
    }
  },

  computed: {

  },

  watch: {
    /** Convert CSV to JSON */
    csv(current, old) {
      if (current !== old) {
        const csv = current.data
        const headers = csv.shift()
        const json = []
        // eslint-disable-next-line array-callback-return
        csv.map(row => {
          const obj = {}
          // eslint-disable-next-line array-callback-return
          headers.map((item, index) => {
            Object.assign(obj, { [item]: row[index] })
          })
          json.push(obj)
        })
        this.importCSV(json)
      }
    },
  },

  mounted() {
    this.getCurrentWeeek()
    /** get trip lists */
    this.getTrips(this.weekStart, this.weekEnd)
  },

  methods: {
    // get start and end day of current week
    getCurrentWeeek() {
      const curr = new Date() // get current date
      const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
      const last = first + 6 // last day is the first day + 6
      
      const firstday = new Date(curr.setDate(first)).toUTCString()
      const lastday = new Date(curr.setDate(last)).toUTCString()

      this.weekStart = moment(firstday).format('YYYY-MM-DD')
      this.weekEnd = moment(lastday).format('YYYY-MM-DD')
    },

    /** get start and end day when click header button of calender */
    getDayOfWeek(week) {
      const date = week.split(' ')
      if (date.length === 5) {
        this.weekStart = moment(`${date[0]} ${date[1]}, ${date[4]}`).format('YYYY-MM-DD')
        this.weekEnd = moment(`${date[0]} ${date[3]}, ${date[4]}`).format('YYYY-MM-DD')
      } else if (date.length === 6) {
        this.weekStart = moment(`${date[0]} ${date[1]}, ${date[5]}`).format('YYYY-MM-DD')
        this.weekEnd = moment(`${date[3]} ${date[4]}, ${date[5]}`).format('YYYY-MM-DD')
      } else if (date.length === 7) {
        this.weekStart = moment(`${date[0]} ${date[1]}, ${date[2]}`).format('YYYY-MM-DD')
        this.weekEnd = moment(`${date[4]} ${date[5]}, ${date[6]}`).format('YYYY-MM-DD')
      }
      this.getTrips(this.weekStart, this.weekEnd)
    },

    /** Get Table Data */
    getTrips(start, end) {
      this.$store.commit('set_loading_action', true)
      tripService.getTrips({ start, end })
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.items = res.data.trips
          this.totalRows = this.items.length
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('set_loading_action', false)
        })
    },

    /** Upload CSV */
    uploadCSV(file) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upload it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.readFile(file, output => {
            this.csv = this.$papa.parse(output, { skipEmptyLines: true })
          })
        }
      })
    },

    /** Read the CSV */
    readFile(file, callback) {
      if (file) {
        const reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        // eslint-disable-next-line func-names
        reader.onload = function (evt) {
          callback(evt.target.result)
        }
        // eslint-disable-next-line func-names
        reader.onerror = function () {}
      }
    },

    /** Post Upload CSV */
    importCSV(items) {
      this.$store.commit('set_loading_action', true)
      tripService.importCSV({ payload: items, start: this.weekStart, end: this.weekEnd })
        .then(() => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: 'CSV has been uploaded successfully!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.getTrips(this.weekStart, this.weekEnd)
            }
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Fail!, Check current row of csv.',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              console.log('')
            }
          })
        })
    },

    /** format data of current week */
    format() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I will Delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          tripService.format({ start: this.weekStart, end: this.weekEnd })
            .then(res => {
              this.$store.commit('set_loading_action', false)
              this.getTrips(this.weekStart, this.weekEnd)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  
  .margin-0 {
    margin: 0px;
  }

  .fc-view-harness {
    display: none !important;
  }

  label {
    margin-bottom: 0px;
  }

  .fc-prev-button, .fc-next-button{
    color: black !important;
    background-color: #f6f6f6 !important;
    border-color: #f6f6f6 !important;
    background-image: none !important;
  }
</style>
